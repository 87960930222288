.footerWrapper {
  .footer {
    text-align: center;

    a {
      color: black;
    }
  }

  .index {
    margin-top: 2rem;
    padding: 0;
    color: rgba(255, 255, 255, 0.8);
    background: transparent;
  }
}
